<style scoped>
    #hive_per_page {
        width: 100px;
        display: inline;
    }
</style>
<template>
    <b-card no-body cols="12">
        <b-col>
            <b-row cols="12">
                <b-col md="6">
                    <h2 class="text-blue font-weight-bold mt-1">Aequitex Fees</h2>

                </b-col>
                <b-col align="end" class="col-md-6 mt-1 pr-2">
                    <b-button
                        @click="exportFees"
                        variant="primary"
                        v-if="!exportLoading"
                    >
                        Export
                    </b-button>
                    <img src="/loader.svg" height="50" v-else/>
                </b-col>
                <b-col md="12">
                    <b-row>
                        <b-col md="4">
                            <label for="hive_start_date">Start Date</label>
                            <b-form-datepicker @input="runFilter" id="hive_start_date" v-model="start_date" class="mb-2"></b-form-datepicker>
                        </b-col>
                        <b-col md="4">
                            <label for="hive_end_date">End Date</label>
                            <b-form-datepicker @input="runFilter" id="hive_end_date" v-model="end_date" class="mb-2"></b-form-datepicker>
                        </b-col>

                    </b-row>
                </b-col>
            </b-row>

            <br />

            <b-table
                striped
                responsive
                hover
                class="position-relative table-responsive"
                :per-page="per_page"
                :current-page="0"
                :items="data"
                :fields="headers"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                show-empty
            >
                <slot v-for="(_, name) in $slots" :name="name" :slot="name">  </slot>
                <template #head()="scope">
                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                </template>

                <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                ><slot :name="name" v-bind="slotData" />
                </template>
                <template #empty="scope">
                    <div v-if="loader">
                        <CustomLoader :show="true" />
                    </div>
                    <div v-else-if="data.length < 1 && !loader">
                        {{ scope.emptyText }}
                    </div>
                </template>
                <template #cell(__id)="data">
                    <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.__id">
                      <span v-if="data.item.type_description == 'e_t_a_f'">ET-{{data.item.__id}}</span>
                      <span v-else>T-{{data.item.__id}}</span>
                    </p>
                </template>
                <template #cell(invoice_id)="data">
                    <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.invoice_id">
                      <span>I-{{data.item.invoice_id}}</span>
                    </p>
                    <p v-else>-</p>

                </template>
              <template #cell(user_id)="data">
                    <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.user_id">
                        U-{{data.item.user_id}}
                    </p>
                </template>
                <template #cell(company_id)="data">
                    <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.company_id">
                        C-{{data.item.company_id}}
                    </p>
                </template>
                <template #cell(user)="data">
                    <span>
                        {{data.item.fname}} {{data.item.lname}}
                    </span>
                </template>

                <template #cell(currency)="data">
                    <span v-if="data.item.currency">{{ data.item.currency }}</span>
                </template>

                <template #cell(hive_fee)="data">
                    <span>
                        {{amountFormat(data.item.amount, data.item.currency)}}
                    </span>
                </template>
                <template #cell(created_at)="data">
                    <span>
                        {{data.item.created_at || formatDate}}
                    </span>
                </template>
                <!--<template #cell(user_id)="data">-->
            <!--<span v-if="data.item.company && data.item.company.main_contact_person">{{-->
              <!--data.item.company.main_contact_person[0].user.id-->
            <!--}}</span>-->
                    <!--<span v-else>-->
                        <!-- - -->
                    <!--</span>-->
                <!--</template>-->
                <template #cell(type_description)="data">
                  <span v-if="data.item.type_description == 'e_t_a_f'">
                    Exchange Transaction Aequitex Fee
                  </span>
                  <span v-else>
                    {{ data.item.type_description }}
                  </span>
                </template>
                <!--<template #cell(user)="data">-->
                    <!--<span v-if="data.item.company && data.item.company.main_contact_person">{{ data.item.company.main_contact_person[0].user.name }} {{ data.item.company.main_contact_person[0].user.last_name }}</span>-->
                    <!--<span v-else>-</span>-->
                <!--</template>-->
                <template #cell(status)="data">
                    {{ getFeeStatus(data.item) }}
                </template>
            </b-table>
            <b-card-body class="d-flex justify-content-end flex-wrap pt-0">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div>
                        Show
                        <b-form-select @change="runFilter" v-model="per_page" id="hive_per_page" :options="page_options"></b-form-select> items
                    </div>
                    <b-pagination
                        @input="paginateItems"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="per_page"
                        aria-controls="my-table"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </b-card-body>
        </b-col>
    </b-card>
</template>

<script>

  import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

  export default {
    // name: "AequitexFeeList",
    data() {
      return {
        pageOptions: [3, 5, 12],
        totalRows: 1,
        currentPage: 1,
        sortBy: "id",
        sortDesc: true,
        sortDirection: "asc",
        filter: "",
        tableKey: "initTable",
        loader: false,
        headers: [
          {
            key: "__id",
            label: "id",
          },
          {
            key: "invoice_id",
            label: "Invoice id",
          },
          {
            key: "user_id",
            label: "U-ID",
          },
          {
            key: "company_id",
            label: "C-ID",
          },
          {
            key: "user",
            label: "User",
          },
          {
            key: "company_name",
            label: "Company",
          },
          {
            key: "type_description",
            label: "Description",
          },
          {
            key: "currency",
            label: "currency",
          },
          {
            key: "hive_fee",
            label: "amount",
          },
          {
              key: "admin_transaction_reason",
              label: "reference_number_payment_reason",
          },
          {
            key: "status",
            label: "status",
          },
          {
            key: "created_at",
            label: "Date",
          },
        ],
        totalItems: 0,
        data: [],
        start_date: '',
        end_date: '',
        compFilter: "all",
        per_page: 200,
        page_options: [
          { value: 20, text: '20' },
          { value: 50, text: '50' },
          { value: 100, text: '100' },
          { value: 200, text: '200' },
        ],
        typeOptions: [{ value: '', text: 'All Types' }],
        selectedType: '',
        exportLoading: false
      };
    },
    created() {
      this.paginateItems();
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    methods: {

      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },

      getFeeStatus(item) {
        let transaction = item,
            transactionStatus = this.$t('approved');
        if(transaction) {
          if (transaction.status == 0) {
            transactionStatus = this.$t('pending');
          } else if (transaction.status == 2) {
            transactionStatus = this.$t('declined');
          }
        }

        return transactionStatus;
      },
      async exportFees() {
        this.exportLoading = true;
        try {
          const response = await this.$http.post('/transactions/admin/exportFees', {
            responseType: 'blob', // Important to set responseType as 'blob'
            start_date: this.start_date,
            end_date: this.end_date,
            selectedType: this.selectedType,
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Aequitex Fees Report.csv');
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        } catch (error) {
          console.error('Error exporting CSV:', error);
          this.exportLoading = false;
        }
      },
      runFilter() {
        this.currentPage = 1;
        this.paginateItems();
      },
      paginateItems(type) {
        this.loader = true;
        this.data = [];
        this.$http
            .post(
                `/transactions/admin/fees?page=${this.currentPage}&sortDesc=${
                    this.sortDesc ? "desc" : "asc"
                    }&sortBy=${this.sortBy}&from=reports`,
                {
                  start_date: this.start_date,
                  end_date: this.end_date,
                  selectedType: this.selectedType,
                  per_page: this.per_page
                }
            )
            .then((res) => {
              if (res) {
                if (res.data.value && res.data.value.data) {
                  this.data = res.data.value.data;
                  this.totalItems = res.data.value.total;
                  this.currentPage = res.data.value.current_page;
                } else if (res.data.value) {
                  this.data = res.data.value;
                  this.totalItems = res.data.total;
                  this.currentPage = res.data.current_page;
                }

                this.$emit("emitNextStep", this.data);
              }
              this.loader = false;
            });
      }
    }
  }
</script>

<style scoped>

</style>