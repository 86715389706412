<template>
  <div>
    <b-card>
      <TransactionsList
          :url="url"
          :fields="fields"
          :body="body"
          :updateData="makeCall"
          :showSearch="false"
          :page="'transactions'"
          :title="$t('transactions')"
          @actionButtonTab="actionButtonTab"
      >
        <template #cell(id)="data">
          <b-link @click="showTransaction(data.item)">
            {{ data.item.id }}
          </b-link>
        </template>
        <template #cell(amount)="data">
          <span v-if="data.item.amount">{{amountFormat(data.item.amount, data.item.currency.name)}}</span>
          <span v-else>-</span>
        </template>
        <template #cell(currency)="data">
          <span>{{data.item.currency.name}}</span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.value == 0">{{ $t("pending") }} </span>
          <span v-if="data.value == 1">{{ $t("approved") }}</span>
          <span v-if="data.value == 2">{{ $t("declined") }}</span>
          <span class="text-secondary" v-if="data.value == 3">{{
              $t("proof_of_ownership_is_in_progress")
            }}</span>
        </template>
      </TransactionsList>
    </b-card>
  </div>
<!--    <b-tabs content-class="pt-1" fill v-model="tabIndex">-->
    <!--      <b-tab>-->
    <!--          <template #title>-->
    <!--              <span>{{ $t("transactions") }}</span>-->
    <!--          </template>-->
    <!--        -->
    <!--      </b-tab>-->
    <!--      <b-tab>-->
    <!--        <template #title>-->
    <!--          <span>{{ $t("Aequitex Fees") }}</span>-->
    <!--        </template>-->
    <!--        <b-card>-->
    <!--          <AequitexFeeList />-->
    <!--        </b-card>-->
    <!--      </b-tab>-->
    <!--    </b-tabs>-->

</template>
<script>
    import TransactionsList from "../../components/Reports/TransactionsList.vue";
    import AequitexFeeList from "../../components/Reports/AequitexFeeList.vue";
    import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';
    export default {
        components: { TransactionsList, AequitexFeeList },
        data() {
            return {
                tabIndex: 0,
                url: "/transactions/admin",
                button: {
                    text: "deposit",
                    link: "",
                    color: "primary",
                },
                // fields: [
                //     {
                //         key: "id",
                //         label: "T-ID",
                //     },
                //     {
                //         key: "user_id",
                //         label: "U-ID",
                //     },
                //     {
                //         key: "invoice_id",
                //         label: "I-ID",
                //     },
                //     {
                //         key: "user",
                //         label: "User",
                //     },
                //     {
                //         key: "company",
                //         label: "Company",
                //     },
                //     {
                //         key: "type_description",
                //         label: "Description",
                //     },
                //     {
                //         key: "currency",
                //         label: "currency",
                //     },
                //     {
                //         key: "amount",
                //         label: "amount",
                //     },
                //     {
                //         key: "status",
                //         label: "status",
                //     },
                //     {
                //         key: "role",
                //         label: "role"
                //     },
                //     {
                //         key: "admin_transaction_reason",
                //         label: "reference_number_payment_reason",
                //     },
                //     {
                //         key: "created_at",
                //         label: "Date",
                //     },
                // ],

              fields:  [
                  {
                    key: "__id",
                    label: "T-ID",
                  },
                  {
                    key: "invoice_id",
                    label: "I-ID",
                  },
                  {
                    key: "user_id",
                    label: "U-ID",
                  },
                  {
                    key: "company_id",
                    label: "C-ID",
                  },
                  {
                    key: "user",
                    label: "User",
                  },
                  {
                    key: "company_name",
                    label: "Company",
                  },
                  {
                    key: "type_description",
                    label: "Description",
                  },
                  {
                    key: "currency",
                    label: "currency",
                  },
                  {
                    key: "status",
                    label: "status",
                  },
                {
                  key: "amount",
                  label: "amount",
                },
                {
                  key: "payment_reason",
                  label: "reference_number_payment_reason",
                },
                {
                    key: "created_at",
                    label: "Date",
                  },
                ],
                table: true,
                deposit: null,
                transactions: [],
                body: {
                    company_id: this.$route.params.id,
                },
            };
        },

        created() {},
        props:['companyData', 'data'],
        methods: {

          amountFormat(amount, currency) {
            return CurrencyAmountFormat(amount, currency)
          },

            storeDeposit() {
                this.getTransactions();
            },
            showTransaction(item) {
                this.deposit = item;
                this.table = false;
            },
            actionButtonTab() {
                this.deposit = null;
                this.table = false;
            },
            makeCall() {
                this.getTransactions();
            },
            showTable() {
                this.table = true;
            },
            getTransactions() {
                this.$http
                    .post("/transactions/admin", {action: 'reports'})
                    .then((res) => {
                        if (res) {
                          this.transactions = res.data.value.data;
                            this.table = true;
                        }
                    });
            },
        },
    };
</script>
